<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div>
          <h2 class="mb-1">{{ title }}</h2>
          <p class="mb-2">{{ text }}</p>
  
          <b-button
            variant="primary"
            class="mb-2 d-inline-block"
            :to="{ path: btnLink }"
            v-if="isBtn"
          >
            {{ btnText }}
          </b-button>
          
        </div>
        
      
        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
          :style="{ maxWidth: '400px'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'

export default {
    name: "PageNotFound",
		components: {
				BLink,
				BButton,
				BImg,
		},
    props: {
    		title: {
    			type: String,
          default: 'Page Not Found 🕵🏻‍♀',
        },
        text: {
        		type: String,
            default: 'Oops! 😖 The requested URL was not found on this server.',
        },
        isBtn: {
    				type: Boolean,
            default: true
        },
        btnText: {
    				type: String,
            default: 'На главную'
        },
        btnLink: {
    				type: String,
            default: '/'
        }
    },
    data() {
        return {
            downImg: require('@/assets/images/pages/error.svg'),
        }
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                this.downImg = require('@/assets/images/pages/error-dark.svg')
                return this.downImg
            }
            return this.downImg
        },
    },
}
</script>

<style scoped>

</style>
