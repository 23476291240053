<template>
  <div>
    <div v-if="task">
      <b-row>
        <b-col sm="12" class="mb-1">
          <b-button variant="primary" @click="save">Сохранить</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="8">
          <div class="card">
            <div class="card-body">
              <div class="mb-1 d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <feather-icon
                    :icon="iconTask(task.type.name)"
                    :class="colorTask(task.type.name)"
                  />
                  <span class="text-muted ml-1">Задача {{ task.key }}</span>
                </div>

                <b-dropdown variant="outline-secondary" size="sm" no-caret right>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="14"
                      class="text-body cursor-pointer"
                    />
                  </template>
                  <b-dropdown-item @click="deleteTask"> Удалить задачу </b-dropdown-item>
                </b-dropdown>
              </div>

              <task-content
                :task="task"
                :dictionaries="dictionaries"
                :lengthName="false"
              ></task-content>
            </div>
          </div>
        </b-col>

        <b-col sm="4">
          <div class="card">
            <div class="card-header">Сведения</div>
            <div class="card-body">
              <task-info :task="task" :config="config" :dictionaries="dictionaries"> </task-info>
            </div>
            <!-- /.card-body -->
          </div>
        </b-col>
      </b-row>
    </div>
    <page-not-found
      v-if="isError"
      title="Task not found"
      btnText="Назад в проект"
      :btnLink="'/project/' + currentProject + '/board'"
    ></page-not-found>
  </div>
</template>

<script>
import { BRow, BCol, BDropdown, BDropdownItem, BButton } from 'bootstrap-vue';
import TaskContent from './TaskContent';
import TaskInfo from './TaskInfo';
import PageNotFound from '../components/PageNotFound';
import { colorTask, iconTask } from '@/helpers';
import { taskApi, projectApi } from '@/api';
import { mapState, mapActions } from 'vuex';
import { updateTask, dataTask } from '@/mixins/task';
import { getErrors } from '@/helpers/errors';

export default {
  name: 'TaskSingle',
  components: {
    TaskContent,
    TaskInfo,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    PageNotFound,
  },
  data: () => ({
    colorTask,
    iconTask,
    config: null,
    dictionaries: null,
    task: null,
    isError: false,
  }),
  computed: {
    ...mapState('project', ['currentProject', 'activeTask']),
  },
  mixins: [updateTask, dataTask],
  watch: {
    activeTask: function (val) {
      this.task = val;
    },
  },
  methods: {
    ...mapActions('project', ['getActiveSprintStatus']),
    save() {
      const data = this.getTaskData(this.task);
      this.updateTask(this.task.number, data);
    },
    deleteTask() {
      let result = confirm(this.$t('Are you sure you want to delete the task?'));
      if (result) {
        taskApi
          .deleteTask(this.currentProject, this.task.number)
          .then((res) => {
            this.$router.push({
              name: 'projects-backlog',
              params: { project: this.currentProject },
            });
          })
          .catch(() => {
            this.$bvToast.toast(this.$t('Error when deleting a task'), {
              title: this.$t('Delete task'),
              variant: 'danger',
              solid: true,
            });
          });
      }
    },
  },
  mounted() {
    let taskNumber = this.$route.params.task;
    taskApi
      .task(this.currentProject, taskNumber)
      .then((res) => {
        this.$store.dispatch('project/updateActiveTask', res.data.task);
        this.task = res.data.task;
        document.title = this.task.name;
      })
      .catch((error) => {
        this.isError = true;
        return;
        console.log(getErrors(error.response.data.errors));
      });
    const getConfig = projectApi.getConfig(this.currentProject);
    const getDictionaries = projectApi.getDictionaries(this.currentProject);
    this.getActiveSprintStatus();

    Promise.all([getConfig, getDictionaries]).then((results) => {
      this.config = results[0].data.project_config;
      this.dictionaries = results[1].data.project_dictionaries;
    });
  },
};
</script>

<style scoped></style>
